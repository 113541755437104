.component__customComponent__dropbox {
    all: initial;
    width: auto;
    height: 54px;
    min-width: 136px;
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: 5px;
    color: #19191D;
    background-color: #373A43;
    display: block;
    position: relative;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;

    & > img {
        width: 24px;
        height: 24px;
    }
    &__text {
        font-family: "Inter";
        font-weight: 500;
        font-size: 14px;
        line-height: 143%;
        overflow: hidden;
        max-width: calc(100% - 30px);
        height: auto;
        word-break: keep-all;
        white-space: nowrap;
        margin-left: 5px;

        &__placeholder {
            color: #787885;
        }
    }
    &__placeholder {
        position: absolute;
        top: 0;
        left: 12px;
        transform: translateY(-50%);
        color: #787885;
        background-color: white;
        font-family: "Inter";
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
    }
    &__dropdown {
        position: fixed;
        top: 0;
        left: 0;
        width: auto;
        min-width: 300px;
        height: auto;
        max-height: 0;
        border-radius: 8px;
        background-color: #2C2E38;
        overflow-x: auto;
        z-index: 999;
        padding: 0;
        cursor: default;
        
        transition: max-height 0.3s ease, padding 0.3s ease, box-shoadow 0.3s ease;

        &--active {
            max-height: 200px;
            padding: 10px;
            padding-top: 0px;
        }
        &__searchBox {
            width: 100%;
            font-family: "Inter";
            font-weight: 400;
            font-size: 16px;
            border: none;
            border-bottom: 1px solid #787885;
            outline: none;
            box-sizing: border-box;
            height: 35px;
            padding: 10px;
            margin-bottom: 10px;
            background-color: white;
            position: sticky;
            top: 0px;
        }
        &__data {
            font-family: "Inter";
            font-size: 14px;
            padding: 0 1px;
            margin: 10px 0px;
            text-align: start;
            cursor: pointer;

            &:last-child {
                margin-bottom: 0;
            }
            &--noData {
                color: #00A3FF;
            }
        }
    }
}

.component__customComponent__dropbox--dark {
    & .component__customComponent__dropbox {
        background-color: #373A43;

        &__text {
            color: white;
        }
        &__dropdown {
            background-color: #2C2E38;
            color: white;

            &__searchBox {
                color: white;
                background-color: #2C2E38;
            }
        }
    }
}