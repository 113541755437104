@import "../../../scss/mixins.scss";

.customComponents__buttonWithDropdown {
    width: auto;
    height: auto;
    padding: 10px 16px;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: white;
    border-radius: 5px;
    background-color: $button-primary;
    cursor: pointer;

    transition: background-color 0.3s ease;

    &:hover {
        background-color: $button-primary-hover;
    }
    &__dropdown {
        position: absolute;
        top: calc(100% + 10px);
        right: 0;
        width: auto;
        height: auto;
        z-index: 99;
        overflow: hidden;
        opacity: 0;

        background: #2C2E38;
        box-shadow: none;
        border-radius: 5px 0px 0px 5px;

        transition: box-shadow 0.3s ease, max-height 0.3s ease, width 0.3s ease, border 0.3s ease, box-shadow 0.3s ease;

        &--active {
            box-shadow: 0px 0px 19px 5px rgba(0, 0, 0, 0.25);
        }
        &__item {
            width: max-content;
            min-width: 100%;
            height: auto;
            padding: 20px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            
            color: #FFFFFF;
            cursor: pointer;

            transition: background-color 0.3s ease;

            &--active, &:hover {
                background-color: #383B46;
            }
            &--active2 {
                background-color: #6C5DD3;
            }
        }
    }
}