@import "../../scss/mixins.scss";

.component__contentWrapper {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-rows: 102px 1fr;
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding-left: 84px;

    &.cloned-element {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: $breakpoint-medium) {
    .component__contentWrapper {
        padding-left: 0px;
    }
}