@import "../../../scss/mixins.scss";

.component__header__changePassword {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: default;
    background: rgba(35, 38, 48, 0.8);

    &__wrap {
        width: 500px;
        height: auto;
        padding: 40px;

        background: #2C2E38;
        border-radius: 10px;

        &__head {
            width: 100%;
            height: auto;
            margin-bottom: 40px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            & > p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                
                color: #FFFFFF;
            }
            &__btn {
                width: 30px;
                height: 30px;

                background-position: center center;
                background-size: 100%;
                cursor: pointer;

                @include button-hover-animation(34px)
            }
        }
        &__input {
            width: 100%;
            height: auto;
            margin-bottom: 20px;
            
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            &:last-child {
                margin-bottom: 30px;
            }
            & > span {
                margin-bottom: 15px;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                
                color: #FFFFFF;
            }
            & > input {
                width: 100%;
                height: 55px;

                padding: 0 20px;

                color: #fff;
                background: #373A43;
                border: 1px solid transparent;
                outline: none;
                border-radius: 5px;

                transition: border 0.3s ease;

                &:focus {
                    border: 1px solid #6C5DD3;
                }
            }
            &--error {
                & > input {
                    border: 1px solid #FF754C;
                }
            }
        }
        &__btns {
            width: 100%;
            height: auto;

            display: flex;
            justify-content: flex-end;
            gap: 20px;

            &__btn {
                width: max-content;
                height: 43px;
                padding: 0 40px;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;

                display: flex;
                justify-content: center;
                align-items: center;
                
                color: #FFFFFF;                
                background: $button-primary;
                border-radius: 5px;
                cursor: pointer;

                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $button-primary-hover;
                }
                &--secondary {
                    background-color: $button-secondary;

                    &:hover {
                        background-color: $button-secondary-hover;
                    }
                }
            }
        }
        &__infoP {
            margin-top: 20px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            
            color: #FF450D;
            opacity: 0;

            transition: opacity 0.3s ease;

            &--error {
                opacity: 1;
            }
        }
    }
}