@import "../../../scss/mixins.scss";

.route__admin__proxyHistory {
    width: 100%;
    height: auto;

    padding: 0 20px;

    &__singleItem {
        all: initial;
        width: 100%;
        height: 100%;
        position: relative;
    
        cursor: default;
        color: white;
        background-color: rgba(35, 38, 48, 0.8);
    
        &__wrap {
            position: absolute;
            width: 750px;
            max-width: calc(100% - 84px);
            height: 100%;
            top: 0;
            right: -100%;
            padding: 20px;
    
            display: grid;
            grid-template-rows: auto 1fr;
            grid-template-columns: 1fr;
    
            background-color: #2C2E38;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
            transition: width 0.3s ease;
    
            &__top {
                width: 100%;
                height: auto;
                padding-bottom: 25px;
    
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                border-bottom: 1px solid #373A43;
    
                &__left {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;

                    color: #FFF;
                    font-family: Inter;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    &__btn {
                        width: auto;
                        height: auto;
                        padding: 5px 10px;

                        font-family: Inter;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 19px;
                        letter-spacing: 0em;
                        text-align: left;
                        
                        color: white;
                        background-color: $button-primary;
                        border-radius: 5px;
                        cursor: pointer;
                        transition: background-color 0.3s ease;

                        &:hover {
                            background-color: $button-primary-hover;
                        }
                    }
                }
                &__right {
                    background-color: $button-primary;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 3px;
    
                    cursor: pointer;
                    transition: background-color 0.3s ease;
    
                    &:hover {
                        background-color: $button-primary-hover;
                    }
                }
            }
            &__content {
                width: 100%;
                height: 100%;
                overflow: auto;
                color: white;
    
                padding: 15px 0;
    
                & &__text {
                    color: white;
                    margin-bottom: 20px;

                    &--code {
                        display: grid;
                        justify-content: flex-start;
                        align-items: center;
                        grid-template-rows: auto;
                        grid-template-columns: auto 1fr;
                        gap: 10px;

                        & > span:first-child {
                            padding: 5px;
                            background-color: #504a79;
                            border-radius: 5px;
                        }
                        & > span:last-child {
                            font-family: Inter;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18px;
                            letter-spacing: 0em;
                            text-align: left;
                        }
                    }
                    &--response {
                        display: grid;
                        grid-template-columns: 50px 1fr;
                        align-items: center;
                        grid-template-rows: auto;
                        column-gap: 10px;
                        row-gap: 5px;
                        margin-bottom: 5px;

                        & > span:last-child {
                            width: max-content;
                            padding: 5px;
                            background-color: #504a79;
                            border-radius: 5px;

                            font-family: Inter;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 17px;
                            letter-spacing: 0em;
                            text-align: left;                                
                        }
                    }
                    &--statusCode {
                        display: grid;
                        grid-template-columns: 50px 1fr;
                        align-items: center;
                        grid-template-rows: auto;
                        column-gap: 10px;
                        row-gap: 5px;

                        & > span:nth-child(2) {
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            gap: 10px;

                            & > span:first-child {
                                padding: 5px;
                                background-color: #504a79;
                                border-radius: 5px;

                                font-family: Inter;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 17px;
                                letter-spacing: 0em;
                                text-align: left;                                
                            }
                        }
                        & > span:nth-child(3) {
                            grid-column: 1 / span all;
                            color: rgb(155, 154, 154);
                        }
                    }
                }
                & &__line {
                    margin: 10px 0;
                    width: 100%;
                    height: 1px;
                    max-height: 1px;
                    background-color: gray;
                }
                & &__tabs {
                    width: 100%;
                    height: auto;
                    margin-bottom: 20px;

                    display: flex;
                    align-items: center;
                    gap: 10px;

                    &__tab {
                        padding: 10px 18px;
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17px;
                        letter-spacing: 0em;
                        text-align: left;

                        border: 1px solid transparent;                    
                        border-radius: 5px;
                        transition: border 0.3s ease, color 0.3s ease, background-color 0.3s ease;
                        cursor: pointer;

                        &:hover {
                            border-color: #6C5DD3;
                        }
                        &--active {
                            background-color: #6C5DD3;
                            color: white;
                        }
                    }
                }
                & &__internalTab {
                    width: 100%;
                    height: auto;
                    padding: 5px;

                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 10px;
                    border-radius: 5px;

                    background-color: #242731;
                    border-bottom: 1px solid gray;
                    cursor: pointer;

                    &:last-child {
                        border-bottom: none;
                    }
                    & > img {
                        width: 14px;
                        height: 14px;
                        
                        transform: rotate(-90deg);
                        transition: transform 0.3s ease;
                    }
                    & > p {
                        color: white;
                    }
                    &__content {
                        display: none;
                        width: 100%;
                        height: auto;
                        flex-basis: 100%;
                        cursor: default;

                        & > pre {
                            font-family: Inter;
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 20px;
                            letter-spacing: 0em;
                            text-align: left;

                            .string { color: #8bff8b; }
                            .number { color: darkorange; }
                            .boolean { color: #c3c3ff; }
                            .null { color: #f78af7; }
                            .key { color: #fd8a8a; }
                        }
                        &__textarea {
                            display: block;
                            width: 100%;
                            height: max-content;

                            font-family: Inter;
                            font-size: 16px;
                            font-weight: 300;
                            line-height: 19px;
                            letter-spacing: 0em;
                            text-align: left;
                            resize: none;
                            

                            background-color: transparent;
                            color: white;
                            border: none;
                            outline: none;
                        }
                        &--code > * {
                            padding: 5px;
                        }
                        &--preview {
                            & > iframe {
                                width: 100%;
                                height: max-content;
                                background-color: white;
                                min-height: 500px;
    
                                border: none;
                                outline: none;
                            }
                        }
                    }
                }
                &__internalTab {
                    &--active &__content {
                        display: flex;
                    }
                    &--active > img {
                        transform: rotate(0) !important;
                    }
                }
            }
        }
    }
}