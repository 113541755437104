@import "../../../scss/mixins.scss";

.route__admin__users {
    width: 100%;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    &__tableWrap {
        width: auto;
        max-width: 100%;
        height: auto;
        padding-bottom: 5px;
        overflow-y: auto;
        filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.25));
    }
    &__btns {
        width: 100%;
        height: auto;
        margin-bottom: 20px;

        display: flex;
        justify-content: space-between;

        &__left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &__selected {
                width: auto;
                max-width: 0;
                height: auto;
                padding: 10px 0;
                overflow: hidden;
                word-break: keep-all;
                white-space: nowrap;

                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;

                color: #ffffff;
                background: #232630;
                border: 1px solid transparent;
                border-radius: 5px;

                transition:
                    padding 0.3s ease,
                    max-width 0.3s ease,
                    border 0.3s ease;

                &__btn {
                    width: 18px;
                    height: 18px;
                    background-size: 100%;
                    position: relative;
                    cursor: pointer;

                    @include button-hover-animation(22px);
                }
                &--active {
                    max-width: 300px;
                    padding: 10px 18px;
                    border-color: #6c5dd3;
                }
            }
        }
        &__right {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            &__btn {
                width: auto;
                height: auto;
                padding: 10px 16px;

                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 8px;

                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;

                color: #ffffff;
                background-color: $button-primary;
                border-radius: 5px;
                cursor: pointer;

                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $button-primary-hover;
                }
                & > img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
    &__add {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;

        background: rgba(35, 38, 48, 0.8);

        &__wrap {
            width: 500px;
            height: auto;
            max-height: 100%;
            padding: 40px;

            overflow: auto;

            background: #2c2e38;
            border-radius: 10px;
            box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.25);

            &__head {
                width: 100%;
                height: auto;
                margin-bottom: 24px;

                display: flex;
                justify-content: space-between;
                align-items: center;

                &__left {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;

                    color: #ffffff;
                }
                &__right {
                    width: 30px;
                    height: 30px;
                    background-size: contain;
                    position: relative;

                    @include button-hover-animation(34px);
                }
            }
            &__input {
                width: 100%;
                height: auto;
                margin-bottom: 20px;

                display: flex;
                flex-direction: column;
                gap: 14px;

                & > p {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;

                    color: #ffffff;
                }
                & > input {
                    width: 100%;
                    height: 54px;
                    padding: 0 20px;

                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 19px;

                    color: #ffffff;
                    background: #373a43;
                    border: 1px solid #373a43;
                    outline: none;
                    border-radius: 5px;

                    transition: border 0.3s ease;

                    &:focus {
                        border-color: #6c5dd3;
                    }
                }
                &--error > input {
                    border-color: #ff754c;
                }
            }
            &__checkbox {
                width: auto;
                height: auto;
                margin-bottom: 20px;

                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
            }
            &__buttons {
                width: 100%;
                height: auto;

                display: flex;
                justify-content: flex-end;
                gap: 20px;

                &__btn {
                    width: auto;
                    height: 43px;
                    padding: 0px 40px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;

                    color: #ffffff;
                    background-color: $button-primary;
                    border-radius: 5px;
                    cursor: pointer;

                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: $button-primary-hover;
                    }
                    &--secondary {
                        background-color: $button-secondary;

                        &:hover {
                            background-color: $button-secondary-hover;
                        }
                    }
                }
            }
            &__countryList{
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;
                margin-bottom: 20px;
                &>p{
                    display: flex;
                    align-items: center;
                    &>img{
                        width: 24px;
                        height: 18px;
                        margin-right: 4px;
                    }
                }
            }
            &__infoP {
                margin-top: 20px;
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #ff450d;
                opacity: 0;
                transition: opacity 0.3s ease;
            }
        }
    }
}

.users__countries{
    display: flex;
    gap: 12px;
    &>p{
        display: flex;
        align-items: center;
        &>img{
            margin-right: 4px;
        }
    }
}

.country__selection__modal {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;

    background: rgba(35, 38, 48, 0.8);

    &__wrapper {
        width: 500px;
        height: auto;
        max-height: 100%;
        padding: 40px;

        overflow: auto;

        background: #2c2e38;
        border-radius: 10px;

        &__head {
            width: 100%;
            height: auto;
            margin-bottom: 24px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            &__left {
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;

                color: #ffffff;
            }
            &__right {
                width: 30px;
                height: 30px;
                background-size: contain;
                position: relative;

                @include button-hover-animation(34px);
            }
        }

        &__selection{
            display: flex;
            width: 100%;
            gap: 20px;

            .component__customComponent__dropbox{
                width: 70%;
                height: 43px;
            }

            &__add{
                width: 30%;
                display: flex;
                justify-content: center;
                align-items: center;

                width: auto;
                height: 43px;
                padding: 0px 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #ffffff;
                background-color: #6C5DD3;
                border-radius: 5px;
                cursor: pointer;
                transition: background-color 0.3s ease;
            }
        }

        &__items{
            margin-top: 20px;
            max-height: 400px;
            overflow-y: scroll;
            &__item{
                padding: 10px 0px;
                border-bottom: 1px solid rgba(35, 38, 48, 0.8);
                &>p{
                    display: flex;
                    gap: 10px;
                    &>img{
                        width: 24px;
                        height: 18px;
                    }
                    &>span{
                        margin-left: auto;
                        min-width: 100px;
                        text-align: center;
                        background-color: $button-primary;
                        border-radius: 10px;
                        padding: 2px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        transition: background-color 0.3s ease;
                        &:hover {
                            background-color: $button-primary-hover;
                        }
                    }
                }
            }
        }

        &__bottom{
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            margin-top: 10px;
            &__button{
                width: auto;
                height: 43px;
                padding: 0px 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #ffffff;
                background-color: $button-primary;
                border-radius: 5px;
                cursor: pointer;
                transition: background-color 0.3s ease;
                &:hover {
                    background-color: $button-primary-hover;
                }
            }
        }
    }
}
