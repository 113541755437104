.dataSorter{
  display: flex;
  gap: 30px;
  margin-bottom: 10px;
  &__btn{
    width: auto;
    height: 45px;
    min-width: 136px;
    border: 1px solid #FFF;
    box-sizing: border-box;
    border-radius: 4px;
    color: white;
    display: block;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    &>p{
      width: 100%;
      text-align: center;
    }
  }
}