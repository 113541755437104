.route__user__sms {
    width: 100%;
    height: auto;

    padding: 0 20px;

    & .customComponents__table__data__group {
        gap: 5px;
        justify-content: flex-start;
        & > button {
            margin: 0 !important;
        }
    }
    &__messages {
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: column;
        gap: 10px;

        &__message {
            width: 100%;
            height: auto;
            padding: 8px;

            background-color: #323644;
            color: white;
            border-radius: 5px;
            box-shadow: 0 0 4px 2px rgba(0,0,0,0.25);

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 10px;

            font-family: Inter;
            font-size: 18px;
            font-weight: 400;
            line-height: 20px;
            text-align: start;

            &__name {
                width: 100%;

                display: flex;
                justify-content: space-between;

                & > span:first-child {
                    font-weight: bold;
                }
                & > span:last-child {
                    color: #ffffff61;
                }
            }
            &__text {
                font-size: 16px;
            }
        }
    }
}