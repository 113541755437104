.route__dashboard {
    width: 100%;
    height: 100%;

    &__filters {
        width: 100%;
        height: auto;

        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: auto;
        column-gap: 30px;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 20px;

        &--additional {
            grid-template-columns: 1fr 1fr auto;
        }
        & .component__filters__filterColumn {
            height: 40px;
        }
    }
    &__kpi {
        width: 100%;
        height: auto;
        margin-bottom: 32px;

        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: auto;
        grid-auto-rows: auto;
        row-gap: 20px;

        &__item {
            width: 100%;
            height: 120px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            padding: 24px 40px;
            border: 1px solid #373A43;

            &__top {
                color: #FFF;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
            }
            &__bottom {
                color: #FFF;
                font-family: Inter;
                font-size: 28px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 1px;
            }
        }
    }
    &__chart {
        width: 100%;
        height: auto;
        position: relative;
        margin-bottom: 32px;

        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;

            background-color: rgba(35, 38, 48, 0.8);

            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;

            &--active {
                opacity: 1;
                pointer-events: all;
            }
        }
    }
    &__tableOrders {
        width: 100%;
        height: auto;
        margin-bottom: 32px;
    }
    &__tables {
        width: 100%;
        height: auto;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);

        row-gap: 20px;
        column-gap: 20px;
    }
}