@import "../../../scss/mixins.scss";

.route__proxyAssignments {
    width: 100%;
    height: auto;
    padding: 0 20px;

    &__buttons {
        width: 100%;
        height: auto;
        margin-bottom: 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        &__left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &__selected {
                width: auto;
                max-width: 0;
                height: auto;
                padding: 10px 0;
                overflow: hidden;
                word-break: keep-all;
                white-space: nowrap;

                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;

                color: #ffffff;
                background: #232630;
                border: 1px solid transparent;
                border-radius: 5px;

                transition:
                    padding 0.3s ease,
                    max-width 0.3s ease,
                    border 0.3s ease;

                &__btn {
                    width: 18px;
                    height: 18px;
                    background-size: 100%;
                    position: relative;
                    cursor: pointer;

                    @include button-hover-animation(22px);
                }
                &--active {
                    max-width: 300px;
                    padding: 10px 18px;
                    border-color: #6c5dd3;
                }
            }
        }
        &__right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        &__btn {
            width: auto;
            height: auto;
            padding: 10px 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #ffffff;
            background-color: #6C5DD3;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #5A49CE;
            }
            & > img {
                width: 20px;
                height: 20px;
            }
        }
    }
    &__quotaLine {
        width: auto;

        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;

        &__line  {
            position: relative;
            width: 100%;
            height: auto;
            height: 2px;

            background-color: gray;
            border-radius: 999px;

            & > span {
                position: absolute;
                top: 0;
                left: 0;
                width: 0%;
                height: 100%;
                max-width: 100%;

                border-radius: 999px;
            }
        }
    }
    &__assign {
        &__splitInput {
            width: 100%;
            height: auto;
            flex-basis: 100%;

            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 1fr 100px;
            gap: 10px;

            & .component__customComponent__dropbox {
                min-width: 0;
            }
        }
    }
}