.route__user__proxies {
    width: 100%;
    height: auto;

    padding: 0 20px;

    & .customComponents__table__data__group {
        gap: 5px;
        justify-content: flex-start;
        
        & > button {
            margin: 0 !important;
        }
    }
    &__quotaLine {
        width: auto;

        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;

        &__line  {
            position: relative;
            width: 100%;
            height: auto;
            height: 2px;

            background-color: gray;
            border-radius: 999px;

            & > span {
                position: absolute;
                top: 0;
                left: 0;
                width: 0%;
                max-width: 100%;
                height: 100%;

                border-radius: 999px;
            }
        }
    }
}