@import "../../scss/mixins.scss";

.component__sidebar {
    width: 84px;
    height: 100%;
    padding: 38px 20px;
    border-right: 1px solid #373A43;

    display: flex;
    flex-direction: column;

    overflow-y: auto;
    overflow-x: hidden;

    transition: 0.3s ease;

    position: fixed;
    top: 0;
    left: 0;

    z-index: 51;
    background-color: #232630;

    &__logo {
        width: 100%;
        min-width: 200px;
        height: auto;
        margin-bottom: 35px;

        display: flex;
        align-items: center;
        gap: 20px;

        & > img {
            width: 42px;
            height: auto;
        }
        & > p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 33px;
            
            word-break: keep-all;
            color: #FFFFFF;
        }
        &__x{
            display: none;
        }
    }
    &__item {
        width: 100%;
        height: 56px;
        padding: 0 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        position: relative;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;
        cursor: pointer;
        
        transition: background-color 0.3s ease;

        & > svg {
            width: 24px;
            height: 24px;
            flex-grow: 0;
            flex-shrink: 0;
        }
        & > p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            min-width: 200px;
            
            color: #808191;

            transition: color 0.3s ease, font-weight 0.3s ease;

            
        }
        & > svg * {
            transition: fill 0.3s ease, stroke 0.3s ease;
        }
        &:hover {
            & > p {
                color: white;
            }
            & > svg .foreground {
                fill: white;
            }
            & > svg .foreground2 {
                stroke: white;
            }
        }
        &--active {
            background-color: #6C5DD3;


            & > p {
                color: white;
                font-weight: 500px;
            }
            & > svg .foreground {
                fill: white;
            }
            & > svg .foreground2 {
                stroke: white;
            }
        }
    }
    &__line {
        // width: calc(100% - 20px);
        width: 100%;
        height: 1px;
        margin: 30px 0;
        position: relative;
        left: 0px;

        background-color: #373A43;
    }

    &__text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        margin-left: 0px;
        margin-bottom: 30px;
        
        color: #808191;

        white-space: nowrap;

        span{
            display: inline-block;
            transition: 0.3s ease;
        }

        &--short{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }
}
.component_sidebar_hamburger{
    display: none;
}

@media (max-width: $breakpoint-medium) {
    .component__sidebar {
        width: 0px;
        padding: 0px;

        &__logo {
            gap: 14px;

            & > img {
                width: 32px;
            }
            & > p {
                font-size: 18px;
            }
            &__x{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 32px;
                height: 32px;
                margin-left: auto;
                cursor: pointer;

                transition: 0.3s ease;

                &:hover{
                    transform: scale(1.2);
                }

                &__line{
                    width: 34px;
                    height: 1px;
                    background-color: white;
                    transform: rotate(45deg);
                    position: absolute;
                }
                &__line-2{
                    width: 34px;
                    height: 1px;
                    background-color: white;
                    transform: rotate(-45deg);
                    position: absolute;
                }
            }
        }
    }
}

.component__sidebar--opened {
    width: 256px;
    padding: 38px 20px;
    z-index: 99;
}