$button-primary: #6C5DD3;
$button-primary-hover: #5A49CE;

$button-secondary: #1B1D21;
$button-secondary-hover: #0D0F11;

$breakpoint-extraExtraLarge: 1400px;
$breakpoint-extraLarge: 1200px;
$breakpoint-large: 992px;
$breakpoint-medium: 768px;
$breakpoint-small: 576px;

@mixin button-hover-animation($size, $position:"relative") {
    position: unquote($position);

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: $size;
        height: $size;
        transform: translate(-50%, -50%);
        background-color: transparent;
        border-radius: 50%;
        transition: background-color 0.3s ease;
    }
    &:hover::after {
        background-color: rgba(128, 128, 128, 0.208);
    }
    &.cloned-element::after {
        display: none !important;
    }
}