.route__admin__websiteFilters {
    width: 100%;
    height: auto;

    padding: 0 20px;
    color: white;
    font-family: Inter;

    & h3 {
        color: #b7adf8;
        font-weight: 400;
    }
    &__mode {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 30px;

        & > p {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
    &__links {
        width: 100%;
        max-width: 800px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__gray {
            color: gray;
        }
        &__input {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 1fr 150px 200px;
            gap: 20px;

            & > button {
                height: 100%;
            }
        }
        &__list {
            width: 100%;
            height: auto;

            display: flex;
            flex-direction: column;
            align-items: center;

            &__item {
                flex-grow: 0;
                flex-shrink: 0;

                width: 100%;
                height: auto;
                padding: 10px 0;

                border-top: 1px solid gray;
                border-bottom: 1px solid gray;
                border-collapse: collapse;
                
                display: grid;
                grid-template-columns: 1fr repeat(2, 100px);
                grid-template-rows: auto;
                align-items: center;
                gap: 10px;

                &:not(:first-child) {
                    border-top: none;
                }
                & > button {
                    height: 30px;
                    padding: 0 10px;

                    &:last-child {
                        background-color: #D35D5D;

                        &:hover {
                            background-color: #cf2424;
                        }
                    }
                }
            }
        }
    }
}