.styledComponent__button {
    width: auto;
    height: 43px;
    padding: 0 40px;

    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    border-radius: 5px;
    background: #6C5DD3;
    outline: none;
    border: none;

    cursor: pointer;
    
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #5A49CE;
    }
    &[data-secondary] {
        background-color: #1B1D21;

        &:hover {
            background-color: #0D0F11;
        }
    }
    &[data-spinner], &[data-disabled] {
        pointer-events: none;
    }
    &[data-disabled] {
        background-color: #85829B;
    }
}