.route__admin__devices {
    width: 100%;
    height: auto;

    padding: 0 20px;
}

.route__admin__singleDevice {
    width: 100%;
    height: auto;
    color: white;

    padding: 0 20px;

    & .customComponents__table {
        margin-bottom: 10px;
    }
    & h3 {
        font-weight: normal;
        color: #b7adf8;
    }
    &__serverInfo {
        width: 100%;
        height: auto;
        padding: 10px;
        margin-bottom: 10px;

        font-family: Inter;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        line-height: 20px;
        
        color: white;
        background-color: #181a24;
        box-shadow: 0 0 3px 5px rgba(0,0,0,0.3);
    }
}